<template>
  <div id="app">
    <router-view />

  </div>
</template>
<script>
export default {
  name: 'app',
  mounted () {
    // this.$dbInit()
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 18px;
}
</style>
