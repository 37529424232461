import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/List',
    name: 'List',
    component: () => import('../views/List.vue')
  },
  {
    path: '/addList',
    name: 'addList',
    component: () => import('../views/addList.vue')
  },
    {
    path: '/BillOfMaterials',
    name: 'BillOfMaterials',
    component: () => import('../views/components/BillOfMaterials.vue')
  },
    {
    path: '/switchs',
    name: 'switchs',
    component: () => import('../views/components/switchs.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
