import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
// import {dbInit} from './db.js'
Vue.use(Vant);
Vue.config.productionTip = false
// Vue.prototype.$dbInit = dbInit;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
