<template>
  <div class="home">
    <router-link to="/List" v-if="show">
      <div class="bigBtn">材料清单</div>
    </router-link>
    <div class="csh" v-else>
      <span class="kl">初始化口令</span>
      <van-field class="digit" v-model="digit" type="digit" label="口令" center clearable>
        <template #button>
          <van-button size="small" type="primary" @click="yanzheng">验证</van-button>
        </template>
      </van-field>
      <!-- <van-field class="digit" v-model="digit" type="digit" label="口令">
        <template #button>
          <van-button size="small" type="primary">验证</van-button>
        </template>
      </van-field> -->
    </div>
    <div style="position: absolute; bottom: 10px; width: 100%;text-align: center;font-size: 12px;"><a
        href="https://beian.miit.gov.cn/">渝ICP备2021005113号-1</a></div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'HomeView',
  data () {
    return {
      show: false,
      digit: ''
    }
  },
  created () {
    this.dbInit()
    this.check();
  },
  components: {
  },
  methods: {
    // yanzheng
    yanzheng () {
      if (this.digit == 13996673833) {
        this.init().then(
          this.check()
        )
      } else {
        Toast.fail('口令错误');
      }
    },
    // async deleteDb (dbname) {
    //   const delReq = indexedDB.deleteDatabase(dbname);

    //   delReq.onerror = (event) => {
    //     console.error('删除数据库时出错.');
    //   };

    //   delReq.onblocked = (event) => {
    //     console.error('阻止删除数据库.');
    //   };

    //   delReq.onsuccess = (event) => {
    //     console.log('数据库删除成功');
    //     db.close();
    //     console.log('数据库已过时，请重新加载页面.');
    //   };
    // },
    async dbInit () {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open('user', 2);

        request.onblocked = (event) => {
          alert("请关闭其他由该站点打开的页签！");
        };

        request.onerror = (event) => {
          reject(event.target.error);
        };

        request.onupgradeneeded = (event) => {
          const db = event.target.result;


          if (!db.objectStoreNames.contains('user')) {
            db.createObjectStore('user', { keyPath: 'id', autoIncrement: true });
            // if (!db.objectStoreNames.contains('person')) {
            //   db.createObjectStore('person', { keyPath: 'id', autoIncrement: true });
            // }
          }
        };

        request.onsuccess = (event) => {
          const db = event.target.result;

          db.addEventListener('versionchange', () => {
            console.log('数据库版本已更改');
          });

          resolve(db);
        };
      });
    },
    async dbOperation (type, data) {
      const typeList = ['add', 'get', 'getAll', 'delete', 'put'];

      if (!typeList.includes(type)) {
        throw new Error(`操作类型错误，仅支持: ${typeList.join(', ')} 方法`);
      }

      const readType = ['add', 'delete', 'put'].includes(type) ? 'readwrite' : 'readonly';
      const db = await this.dbInit();
      const transaction = db.transaction('user', readType);
      const objectStore = transaction.objectStore('user');

      return new Promise((resolve, reject) => {
        const request = objectStore[type](data);

        request.onsuccess = (event) => {
          resolve(event.target.result);
        };

        request.onerror = (event) => {
          reject(event.target.error);
        };

        transaction.oncomplete = () => {
          this.loading = false
        };
      });
    },
    async check (id) {
      // const ids = Number(this.digit)
      const res = await this.dbOperation('get', 13996673833);
      console.log(res === undefined);

      if (res !== undefined) {
        this.show = true
      } else {
        this.show = false
      }
      // console.log(res, 'res');

    },
    async init () {
      const res = await this.dbOperation('add', { id: Number(this.digit), pwd: '13996673833' });
    },
  }
}
</script>
<style scoped>
.home {
  width: 100%;
  height: 100vh;
  background: url(../../public/bg.png) no-repeat fixed center;
  background-size: cover;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  /* filter: blur(5px); */
  /* color: #fff;
  opacity: 1; */
  /* background-image: linear-gradient(180deg, #a1d0ff, #b3d8fd 40%, #ffffff 45%, #ffffff); */
  /* 0deg, blue, green 40%, red */
}

.kl {
  font-size: 20px;
  font-weight: bold;
}

.digit {
  margin-top: 10px;
}

.csh {
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - 100px);
  width: 300px;
  height: 200px;
}

.home::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../../public/bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* backdrop-filter: blur(10px);
  filter: blur(10px); */
  opacity: 0.1;
}

.bigBtn {

  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 200px;
  color: #fff;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  width: 200px;
  height: 200px;
  border-radius: 10px;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px); */
  background-image: linear-gradient(to bottom, #7cbeff, #48a3ff)
}

a {
  color: rgb(186, 186, 186);
  text-decoration: none;
  /* 防止下划线 */
}
</style>